import React from "react";
import { withTranslation } from "react-i18next";
import LoginLayout from "./LoginLayout";
import NoUserPage from "../../security/NoUserPage";
import LoginForm from "./LoginForm";

const LoginPage = ({ t }) => {
  return (
    <NoUserPage>
      <div className="login-page" data-testid="login-page">
        <LoginLayout
          title={t("login.title")}
          subTitle={t("login.sub-title")}
          description={t("login.description")}
        >
          <LoginForm />
        </LoginLayout>
      </div>
    </NoUserPage>
  );
};

export default withTranslation()(LoginPage);
