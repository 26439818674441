import React from "react";
import { Field, reduxForm } from "redux-form";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { Link, useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { reinitPassword, selectRPStatus } from "../../../slices/login";
import {
  required,
  isPassword,
  passwordConfirmed,
} from "../../../utils/validators";
import TextField from "../../TextField/Textfield";
import { formDispatch, loginState } from "../../../utils/utils";

const ReinitPasswordForm = ({
  submitFailed,
  submitting,
  handleSubmit,
  t,
  invalid,
}) => {
  //-- get user token from url.
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const rpStatus = useSelector(selectRPStatus);

  return (
    <form
      onSubmit={handleSubmit((values, dispatch) =>
        formDispatch(dispatch, reinitPassword({ ...values, token }))
      )}
      data-testid="reinit-password-form"
    >
      {rpStatus !== loginState.resolved && (
        <>
          <Field
            name="password"
            type="password"
            component={TextField}
            label={"user.password"}
            validate={[required, isPassword]}
          />
          <Field
            name="passwordConfirmation"
            type="password"
            component={TextField}
            label={"user.passwordConfirmation"}
            validate={[required, passwordConfirmed]}
          />
        </>
      )}
      {submitFailed && <div className="form-error">{t("login.error")}</div>}

      <div className="connexion-buttons-container">
        <div className="form-item connexion-button">
          {rpStatus === loginState.resolved ? (
            <Link to="/login">
              <button className="button button--large" type="button">
                {t("resetPassword.gotologin")}
              </button>
            </Link>
          ) : (
            <button
              className="button button--large"
              type="submit"
              disabled={submitting || invalid}
            >
              {t("resetPassword.submit")}
            </button>
          )}
        </div>
      </div>
    </form>
  );
};

ReinitPasswordForm.propTypes = {
  submitFailed: PropTypes.bool.isRequired,
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func.isRequired,
  invalid: PropTypes.bool.isRequired,
  // login: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

ReinitPasswordForm.defaultProps = {
  submitting: false,
};

const TranslatedFPForm = withTranslation()(ReinitPasswordForm);

const RPForm = reduxForm({
  form: "reinit-form",
  enableReinitialize: true,
})(TranslatedFPForm);

export default RPForm;
