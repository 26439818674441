import React from "react";
import { Field, reduxForm } from "redux-form";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { Link, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { doLogin, isLoggedIn } from "../../../slices/login";
import { required, isEmail } from "../../../utils/validators";
import TextField from "../../TextField/Textfield";
import VisiblePassword from "../../VisiblePassword/VisiblePassword";
import { formDispatch } from "../../../utils/utils";

const LoginForm = ({ submitFailed, submitting, handleSubmit, t, invalid }) => {
  const isUserLoggedIn = useSelector(isLoggedIn);

  if (isUserLoggedIn) return <Navigate to="/my-account" />;

  return (
    <form
      onSubmit={handleSubmit((values, dispatch) =>
        formDispatch(dispatch, doLogin(values))
      )}
      data-testid="login-form"
    >
      <Field
        name="email"
        type="email"
        component={TextField}
        label={"user.email"}
        validate={[required, isEmail]}
      />
      <VisiblePassword
        name="password"
        label={"user.password"}
        validate={required}
      />
      {submitFailed && <div className="form-error">{t("login.error")}</div>}

      <div className="login-form-actions">
        <Link className="form-link" to="/forgotten-password">
          {t("login.passwordForgotten")}
        </Link>

        <button
          className="button button--large"
          type="submit"
          disabled={submitting || invalid}
        >
          {t("login.submit")}
        </button>
      </div>
    </form>
  );
};

LoginForm.propTypes = {
  submitFailed: PropTypes.bool.isRequired,
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func.isRequired,
  invalid: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
};

LoginForm.defaultProps = {
  submitting: false,
};

const TranslatedLoginForm = withTranslation()(LoginForm);

const ReduxLoginForm = reduxForm({
  form: "login-form",
  enableReinitialize: true,
})(TranslatedLoginForm);

export default ReduxLoginForm;
