import { configureStore } from "@reduxjs/toolkit";
import { reducer as formReducer } from "redux-form";
import userReducer from "../slices/users.js";
import { reducer as loginReducer } from "../slices/login.js";
import roleReducer from "../slices/roles.js";

const reducer = {
  form: formReducer,
  users: userReducer,
  roles: roleReducer,
  login: loginReducer,
};

const createMyStore = (preloadedState) => {
  return configureStore({
    reducer: reducer,
    devTools: true,
    preloadedState: preloadedState,
  });
};

const store = createMyStore();

export default store;
export { createMyStore };
