import React from "react";
import MyAccountForm from "./MyAccountForm";
import MyAccountApps from "./MyAccountApps";
import { updateUser } from "../../../slices/users";
import { fetchLoggedUser } from "../../../slices/login";

const updateUserOnSubmit = (values, dispatch) => {
  const data = {
    user: values,
  };
  dispatch(updateUser({ id: data.user.id, data: data })).then(() =>
    dispatch(fetchLoggedUser())
  );
};

const MyAccount = () => {
  return (
    <div className="h-100">
      <div className="my-account-page" data-testid="my-account-page">
        <MyAccountForm onSubmit={updateUserOnSubmit} />
        <MyAccountApps />
      </div>
    </div>
  );
};

export default MyAccount;
