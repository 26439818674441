import React, { useState } from "react";
import Modal from "react-modal";
import { useDispatch } from "react-redux";
import DeleteAccountModal from "./DeleteAccountModal";

const DeleteUserButton = ({ user, doDelete }) => {
  const dispatch = useDispatch();
  const [showDeleteUserModal, setShowDeleteUserModal] = useState(false);

  const openDeleteUserModal = (e) => {
    e.preventDefault();
    setShowDeleteUserModal(true);
  };

  const closeDeleteUserModal = () => {
    setShowDeleteUserModal(false);
  };

  /**
   * Delete given user into backend and state.
   *
   * @param {doDelete} dispatch method for deleting a user
   * @param {object} user
   */
  const handelDeleteUser = (user, doDelete) => {
    closeDeleteUserModal();
    dispatch(doDelete({ id: user.id }));
  };

  return (
    <>
      <i
        onClick={(e) => openDeleteUserModal(e)}
        className="users-list-delete-button icon-delete"
        data-testid="delete-user-button"
      />
      {showDeleteUserModal && (
        <Modal
          className="modal-dialog"
          isOpen={showDeleteUserModal}
          onRequestClose={closeDeleteUserModal}
          ariaHideApp={false}
        >
          <DeleteAccountModal
            onConfirm={() => handelDeleteUser(user, doDelete)}
            onCancel={closeDeleteUserModal}
          />
        </Modal>
      )}
    </>
  );
};

export default DeleteUserButton;
