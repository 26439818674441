import React from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

/**
 * Return a Set of buttons adapted for pagination.
 *
 * @param {Function} t translation
 * @param {*} totalPages total page number.
 * @param {*} currentPage current index page
 * @param {Function} onPageUpdate required when user clic onto one of pagination button.
 * @returns Set of buttons adapted for pagination.
 */
const Pagination = ({ t, totalPages, currentPage, onPageUpdate }) => {
  const button = (index, text, onClick, isDisabled = false) => (
    <button
      key={index}
      className={index === currentPage ? "isActive button" : "button"}
      onClick={onClick}
      disabled={isDisabled}
    >
      {text}
    </button>
  );

  /**
   * Create page index suite array.
   *
   * @returns pages index array.
   */
  const createPages = () => {
    const pages = [];
    for (let i = 0; i < totalPages; i += 1) {
      pages.push(i);
    }
    return pages;
  };

  /**
   * Create pagination Button array.
   *
   * @returns buttons pagination array.
   */
  const createButtons = () => {
    return createPages().map((i) =>
      button(i, i + 1, () => onPageUpdate(i), i === currentPage)
    );
  };

  return (
    <div className="users-pagination">
      {button(
        "previous",
        t("users.previous"),
        () => onPageUpdate(currentPage - 1),
        currentPage === 0 //--disable if first page
      )}
      {createButtons()}
      {button(
        "next",
        t("users.next"),
        () => onPageUpdate(currentPage + 1),
        currentPage === totalPages - 1 //-- disable if last page
      )}
    </div>
  );
};

Pagination.propTypes = {
  t: PropTypes.func.isRequired,
  currentPage: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  onPageUpdate: PropTypes.func.isRequired,
};

export default withTranslation()(Pagination);
