import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import Modal from "react-modal";
import CreateAccountModal from "./CreateAccountModal";
import { createUserWithRoles } from "../../../slices/users";
import { selectRoles } from "../../../slices/roles";
import { useSelector } from "react-redux";
import { application_name } from "../../../utils/utils";

const CreateAccountButton = ({ t }) => {
  const [showCreateAccountModal, setShowCreateAccountModal] = useState(false);

  const openCreateAccountModal = (e) => {
    e.preventDefault();
    setShowCreateAccountModal(true);
  };

  const closeCreateAccountModal = () => {
    setShowCreateAccountModal(false);
  };

  const roles = useSelector(selectRoles);

  const vegaRoles = roles.filter(
    (r) => r.application.name === application_name.vega
  );

  /**
   * Create given user into backend and state.
   *
   * @param {event} e
   * @param {object} user
   */
  const handelCreateAccount = (values, dispatch) => {
    const user = {
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      password: values.password,
    };
    const roles = [{ application: "vega", role: values.vega }];

    if (values.isAdmin) {
      roles.push({ application: "accounts", role: "admin" });
    }

    closeCreateAccountModal();
    dispatch(createUserWithRoles(user, roles));
  };

  return (
    <>
      <p
        onClick={(e) => openCreateAccountModal(e)}
        className="create-account-button button button--medium"
        data-testid="create-account-button"
      >
        {t("users.account-creation.button")}
      </p>
      {showCreateAccountModal && (
        <Modal
          className="modal-dialog"
          isOpen={showCreateAccountModal}
          onRequestClose={closeCreateAccountModal}
          ariaHideApp={false}
        >
          {
            <CreateAccountModal
              doSubmit={handelCreateAccount}
              doCancel={closeCreateAccountModal}
              vegaRoles={vegaRoles}
            />
          }
        </Modal>
      )}
    </>
  );
};

export default withTranslation()(CreateAccountButton);
