import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import actions from "redux-form/lib/actions";
import LoginDataService from "../services/LoginDataService";
import { loginState } from "../utils/utils";

//-- selectors
const selectLogin = (state) => state.login;
const selectLoggedUser = (state) => state.login.loggedUser;
const isLoggedError = (state) => state.login.error;
const isLoggedIn = (state) => !!state.login.loggedUser;

const selectFPStatus = (state) => state.login.FPStatus;
const selectRPStatus = (state) => state.login.RPStatus;
const selectFPEmail = (state) => state.login.FPEMail;

/**
 * Fetch the user from backend and cookie.
 */
const fetchLoginCookie = createAsyncThunk("login", async (values) => {
  const res = await LoginDataService.login(values);
  return res.data;
});

/**
 * Fetch the user from backend and cookie.
 */
const fetchLoggedUser = createAsyncThunk("loggedUser/fetch", async () => {
  const res = await LoginDataService.user();
  return res.data;
});

/**
 * Call backend logout route which clean cookie session.
 */
const fetchLogout = createAsyncThunk("logout/fetch", async () => {
  const res = await LoginDataService.logout();
  return res.data;
});

/**
 * Send mail about forgotten password and refresh description.
 */
const sendEmailForgottenPassword = createAsyncThunk(
  "login/forgottenPassword",
  async (email) => {
    await LoginDataService.forgottenPassword(email);
    return email;
  }
);

/**
 * User password update.
 */
const reinitPassword = createAsyncThunk(
  "login/reinitPassword",
  async (values) => {
    const res = await LoginDataService.reinitPassword(values);
    return res.data;
  }
);

/**
 * Dispatch credentials to get cookie and user.
 * Set logged user into state.
 * This method is catch by middleware thunk redux mechanic.
 *
 * @param {credentials} values
 * @returns
 */
const doLogin = (values) => {
  return (dispatch) => {
    return dispatch(fetchLoginCookie(values)).then((resLog) => {
      return dispatch(fetchLoggedUser());
    });
  };
};

//-- slice to keep login information
const loginSlice = createSlice({
  name: "login",
  initialState: {
    loggedUser: null,
    RPStatus: loginState.pending,
    FPEMail: "",
  },
  extraReducers: {
    [fetchLoginCookie.fulfilled]: (state, action) => {
      state.submitStatus = loginState.resolved;
    },
    [fetchLoginCookie.rejected]: (state, action) => {
      state.error = true;
      state.submitStatus = loginState.error;
    },
    [fetchLoggedUser.fulfilled]: (state, action) => {
      state.loggedUser = action.payload;
      state.error = false;
    },
    [fetchLoggedUser.rejected]: (state, action) => {
      state.error = true;
    },
    [fetchLogout.fulfilled]: (state, action) => {
      state.loggedUser = null;
    },
    [sendEmailForgottenPassword.rejected]: (state, action) => {
      state.FPStatus = loginState.error;
    },
    [sendEmailForgottenPassword.fulfilled]: (state, action) => {
      state.FPEMail = action.payload.email;
      state.FPStatus = loginState.resolved;
    },
    [reinitPassword.rejected]: (state, action) => {
      state.RPStatus = loginState.error;
    },
    [reinitPassword.fulfilled]: (state, action) => {
      state.RPStatus = loginState.resolved;
    },
  },
});

const { reducer } = loginSlice;
export const { refreshLoggedUser } = actions;

export {
  reducer,
  selectLoggedUser,
  selectFPStatus,
  selectRPStatus,
  selectFPEmail,
  selectLogin,
  isLoggedIn,
  isLoggedError,
  fetchLoggedUser,
  fetchLogout,
  sendEmailForgottenPassword,
  reinitPassword,
  doLogin,
};
