import http from "../utils/http-common.js";
class UserDataService {
  getAll(limit, offset) {
    const params = {
      limit: limit,
      offset: offset,
    };
    return http.get("/users", { params: params });
  }
  get(id) {
    return http.get(`/users/${id}`);
  }
  create(data) {
    return http.post("/users", data);
  }
  update(id, data) {
    return http.put(`/users/${id}`, data);
  }
  delete(id) {
    return http.delete(`/users/${id}`);
  }
  deleteAll() {
    return http.delete(`/users`);
  }
  findByEmail(email) {
    return http.get(`/users?email=${email}`);
  }
  count() {
    return http.get("/users/count");
  }
}
export default new UserDataService();
