import React from "react";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { required } from "../../../utils/validators";
import TextField from "../../TextField/Textfield";
import { isAdmin } from "../../../utils/utils";

const MyAccountForm = ({ handleSubmit, t, initialValues }) => {
  const user = initialValues;
  const adminUser = user && isAdmin(user, "accounts");

  return (
    <div className="my-account-page-infos">
      <form onBlur={handleSubmit} data-testid="myaccount-form">
        <Field
          name="firstName"
          type="text"
          component={TextField}
          label={"user.firstName"}
          validate={required}
          readOnly={false}
        />
        <Field
          name="lastName"
          type="text"
          component={TextField}
          label={"user.lastName"}
          validate={required}
          readOnly={false}
        />
      </form>
      <div className="label">{t("user.email")}</div>
      {user && (
        <div className="user-email-value" data-testid="myaccount-form-email">
          {user.email}
        </div>
      )}
      {adminUser && (
        <div className="tagContainer">
          <div className="tag">{t("user.admin")}</div>
        </div>
      )}
    </div>
  );
};

MyAccountForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

MyAccountForm.defaultProps = {
  user: undefined,
};

const TranslatedMyAccountForm = withTranslation()(MyAccountForm);

const ReduxMyAccountForm = reduxForm({
  form: "myaccount-form",
  enableReinitialize: false,
})(TranslatedMyAccountForm);

const InitializeMyAccountForm = connect((state) => ({
  initialValues: state.login.loggedUser, // pull initial values
}))(ReduxMyAccountForm);

export default InitializeMyAccountForm;
