import React from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

const TextField = ({
  input,
  input: { name },
  label,
  autoComplete,
  type,
  meta: { touched, error },
  readOnly = false,
  t,
  className,
}) => {
  className = className ? ` ${className}` : "";
  const hasError = touched && error ? " has-error" : "";
  const classes = `text-field${className}${hasError}`;
  return (
    <div className={classes} data-testid="text-field">
      <label htmlFor={name}>{t(label)}</label>
      <div>
        <input
          id={name}
          {...input}
          type={type}
          readOnly={readOnly}
          autoComplete={autoComplete}
        />
        {touched && error && (
          <div className="form-error" data-testid="text-field-error">
            {t(`error.${name}.${error}`)}
          </div>
        )}
      </div>
    </div>
  );
};

TextField.propTypes = {
  label: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  input: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  meta: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  className: PropTypes.string,
  autoComplete: PropTypes.string,
  readOnly: PropTypes.bool,
};

TextField.defaultProps = {
  className: undefined,
  readOnly: false,
};

export default withTranslation()(TextField);
