import React from 'react';
import LoggedPage from '../../security/LoggedPage.jsx';
import UsersList from "./UsersList.jsx";

/**
 * Page about Users display and management from backend database.
 * 
 * @returns Users page.
 */
const UsersPage = () => {
    return (
       <LoggedPage>
           <UsersList/>
       </LoggedPage>
    );
}

export default UsersPage;