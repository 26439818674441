import React from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { emptyRole } from "../../../utils/utils";
import { Field } from "redux-form";

/**
 * Return a HTML Select react component, adapted for given user and role.
 */
const SelectRoleCreation = ({ t, availableRoles, application, validate }) => {
  //-- all available roles for this application
  const roles = [emptyRole, ...availableRoles];

  return (
    <Field
      name={application}
      component="select"
      validate={validate}
      data-testid={"select-role-for-creation-".concat(application)}
    >
      {roles.map((role) => (
        <option key={application.concat(role.name)} value={role.name}>
          {t("userRole." + role.application.name + "." + role.name)}
        </option>
      ))}
    </Field>
  );
};

SelectRoleCreation.propTypes = {
  t: PropTypes.func.isRequired,
  /**
   * Current available roles for given application.
   */
  availableRoles: PropTypes.array.isRequired,
  application: PropTypes.string.isRequired,
};

export default withTranslation()(SelectRoleCreation);
