import React from "react";

const SwitchButton = ({ value, id, onChange, name = "", className }) => {
  return (
    <div className={className}>
      <input
        checked={value}
        onChange={onChange}
        className="react-switch-checkbox"
        id={`react-switch-new${id}`}
        type="checkbox"
        name={name}
      />
      <label className="react-switch-label" htmlFor={`react-switch-new${id}`}>
        <span
          className={`react-switch-button`}
          data-testid="user-admin-button"
        />
      </label>
    </div>
  );
};

export default SwitchButton;
