import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import {
  fetchLoggedUser,
  selectLoggedUser,
  isLoggedError,
} from "../../slices/login";

/**
 * Wrap any page, and check if user is logged.
 * If logged return child else redirect to loggin page.
 *
 * @param {*} props children which is returned when user is alredy logged.
 * @returns child or redirection.
 */
const LoggedPage = ({ children }) => {
  const dispatch = useDispatch();
  const user = useSelector(selectLoggedUser);
  const loginError = useSelector(isLoggedError);

  useEffect(() => {
    dispatch(fetchLoggedUser());
  }, [dispatch]);

  if (loginError) {
    //-- rejected
    return <Navigate to="/login" />;
  } else if (user) {
    //-- resolved
    return children;
  }
  //-- pending
  return null;
};

export default LoggedPage;
