import React from "react";
import PropTypes from "prop-types";

const LoginLayout = ({ title, subTitle, description, children }) => {
  return (
    <div className="connexion-page" data-testid="login-layout">
      <div className="connexion-page-layout">
        <div className="informations-container">
          <div className="title">{title}</div>
          <div className="sub-title">{subTitle}</div>
          <div className="description">{description}</div>
          {children}
        </div>
      </div>
    </div>
  );
};

LoginLayout.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  description: PropTypes.string,
  children: PropTypes.node.isRequired,
};

LoginLayout.defaultProps = {
  title: undefined,
  subTitle: undefined,
  description: undefined,
};

export default LoginLayout;
