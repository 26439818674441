import React from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

const DeleteAccountModal = ({ t, onConfirm, onCancel }) => (
  <div className="modal-dialog">
    <div className="delete-user-modal">
      <h1 id="delete-user-modal-title" className="modal-title">
        {t("users.delete.modal.title")}
      </h1>
      <div className="delete-user-modal-content">
        {t("users.delete.modal.content")}
      </div>
      <div className="delete-user-modal-buttons">
        <button
          className="delete-user-modal-button-cancel modal-button"
          onClick={onCancel}
          data-testid="delete-account-cancel"
        >
          {t("users.delete.modal.cancel")}
        </button>
        <button
          className="delete-user-modal-button-confirm modal-button"
          onClick={onConfirm}
          data-testid="delete-account-confirm"
        >
          {t("users.delete.modal.confirm")}
        </button>
      </div>
    </div>
  </div>
);

DeleteAccountModal.propTypes = {
  t: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

const TranslatedDeleteAccountModal = withTranslation()(DeleteAccountModal);

export default TranslatedDeleteAccountModal;
