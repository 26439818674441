import React, { useState } from "react";
import { Field } from "redux-form";
import TextField from "../TextField/Textfield";

const VisiblePassword = ({ name, label, validate }) => {
  const [showPassword, setShowPassword] = useState(false);

  const togglePassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="visible-password" data-testid="visible-password">
      <Field
        name={name}
        type={showPassword ? "text" : "password"}
        component={TextField}
        label={label}
        validate={validate}
        readOnly={false}
        autoComplete="new-password"
      />
      <i
        onClick={togglePassword}
        className={
          showPassword
            ? "show-password-button icon-eye-opened"
            : "show-password-button icon-eye-closed"
        }
        data-testid="show-password-button"
      />
    </div>
  );
};

export default VisiblePassword;
