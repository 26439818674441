import React from "react";
import LoggedPage from "../../security/LoggedPage";
import MyAccountLayout from "./MyAccountLayout";

const MyAccountPage = () => {
  return (
    <LoggedPage>
      <MyAccountLayout />
    </LoggedPage>
  );
};

export default MyAccountPage;
