import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, useSearchParams } from 'react-router-dom';
import { selectLoggedUser } from '../../slices/login';

/**
 * Wrap ONLY THE {@link LoginPage}.
 * If logged redirect to my-account page else return child . 
 * 
 * @param {*} props children which is returned when user is NOT logged. 
 * @returns child or redirection.
 */
const NoUserPage = ({ children }) => {    
    const [searchParams] = useSearchParams();
    const user = useSelector(selectLoggedUser);
    
    if (user) {
        if (searchParams.get('redirectTo')) {
            window.location = searchParams.get('redirectTo');
        }
        return <Navigate to="/my-account" />;
    }

    return children;
};
export default NoUserPage;
