import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import User from "./User";
import {
  fetchCount,
  nextPage,
  selectCount,
  selectCurrentPageId,
  selectUsers,
} from "../../../slices/users";
import CreateAccountButton from "./CreateAccountButton";
import { withTranslation } from "react-i18next";
import { retrieveRoles, selectRoles } from "../../../slices/roles";
import Pagination from "../Pagination/Pagination";
import { users_per_page } from "../../../utils/utils";

const UsersList = ({ t }) => {
  const dispatch = useDispatch();
  const users = useSelector(selectUsers);

  //-- get all roles here to avoid backend call
  //-- for each users.
  const roles = useSelector(selectRoles);

  //-- pagination
  const nbTotalElements = useSelector(selectCount);
  const nbPages = Math.ceil(nbTotalElements / users_per_page);
  const currentPage = useSelector(selectCurrentPageId);

  //-- try to load users from backend where users changes
  useEffect(() => {
    dispatch(nextPage(currentPage));
    dispatch(retrieveRoles());
    dispatch(fetchCount());
  }, [currentPage, dispatch]);

  return (
    <div className="users-list-container">
      <div className="users-list-content">
        <CreateAccountButton t={t} />
        <table className="users-list">
          <thead>
            <tr>
              <th>{t("user.lastName")}</th>
              <th>{t("user.firstName")}</th>
              <th>{t("user.email")}</th>
              <th>{t("users.roles.accounts")}</th>
              <th>{t("users.roles.vega")}</th>
            </tr>
          </thead>
          <tbody>
            {users.map((element, pos) => (
              <User key={pos} user={element} roles={roles} />
            ))}
          </tbody>
        </table>
      </div>
      <div>
        <Pagination
          totalPages={nbPages}
          currentPage={currentPage}
          onPageUpdate={(pageId) => dispatch(nextPage(pageId))}
        />{" "}
      </div>
    </div>
  );
};
export default withTranslation()(UsersList);
