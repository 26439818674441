import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectLoggedUser } from "../../../slices/login";
import { deleteUserNRefresh, updateUserRoles } from "../../../slices/users";
import SwitchButton from "../../SwitchButton/SwitchButton";
import { application_name, isAdmin } from "../../../utils/utils";
import DeleteUserButton from "./DeleteUserButton";
import SelectRole from "./SelectRole";

const User = ({ user, roles }) => {
  const vegaRoles = roles.filter(
    (r) => r.application.name === application_name.vega
  );

  const isAdm = isAdmin(user, application_name.accounts);
  const dispatch = useDispatch();
  const loggedUser = useSelector(selectLoggedUser);

  /**
   * Parse event and dispatch role user updating from event values.
   * Only available for admin in this case.
   *
   * @param {event} event
   */
  const handleChangeAdminStatus = (event) => {
    const roleName = event.target.checked ? "admin" : "";
    dispatch(updateUserRoles(application_name.accounts, roleName, user.id));
  };

  /**
   * Parse event and dispatch role user updating from event values.
   *
   * @param {event} event
   */
  const handleSelectRoleVega = (event) => {
    dispatch(
      updateUserRoles(application_name.vega, event.target.value, user.id)
    );
  };

  return (
    <tr key={user.id} className="users-item">
      <td className="user-item-lastName">{user.lastName}</td>
      <td className="user-item-firstName">{user.firstName}</td>
      <td className="user-item-email">{user.email}</td>
      <td>
        {user.id !== loggedUser.id && (
          <SwitchButton
            value={isAdm}
            id={user.id}
            onChange={(event) => handleChangeAdminStatus(event)}
          />
        )}
      </td>
      <td>
        <SelectRole
          user={user}
          application={application_name.vega}
          availableRoles={vegaRoles}
          onSelect={handleSelectRoleVega}
        />
      </td>
      <td>
        {user.id !== loggedUser.id && (
          <DeleteUserButton user={user} doDelete={deleteUserNRefresh} />
        )}
      </td>
    </tr>
  );
};

export default User;
