import isEmailValid from "validator/lib/isEmail";

const required = (value) => (!value ? "required" : undefined);
const isEmail = (value) =>
  value && !isEmailValid(value) ? "invalid" : undefined;
const isPassword = (password) =>
  password && password.length < 8 ? "tooShort" : undefined;
const passwordConfirmed = (confirmationPassword, allValues) =>
  confirmationPassword &&
  allValues &&
  confirmationPassword !== allValues.password
    ? "different"
    : undefined;

export { required, isEmail, isPassword, passwordConfirmed };
