import { unwrapResult } from "@reduxjs/toolkit";
import { SubmissionError } from "redux-form";

const users_per_page = 8;

/**
 * Enum to define into which state are wrapper security classes, during rendering.
 *
 * @see {@link LoggedPage}
 * @see {@link AdminPage}
 * @see {@link NoUserPage}
 */
const loginState = {
  resolved: "resolved",
  pending: "pending",
  error: "error",
};

/**
 * Enum to define managed application names.
 */
const application_name = {
  accounts: "accounts",
  vega: "vega",
  capea: "capea",
};

//-- default empty role when user with no role.
const emptyRole = {
  id: "empty",
  name: "empty",
  application: { name: "empty" },
};

const isAdmin = (user, app_name) => {
  if (!user) return false;
  return (
    user.roles
      .filter((r) => r.application.name === app_name) //-- filter for given app
      .filter((r) => r.name === "admin").length >= 1
  ); //-- check if contain admin role
};

/**
 * Dispatch method as usual but unWrap dispatch result and
 * return an appropriate Submission form error for redux form.
 *
 * @param {*} dispatch redux dispatch method
 * @param {*} submit form submission method.
 * @returns
 */
const formDispatch = (dispatch, submit) => {
  return dispatch(submit).then(unwrapResult).catch(throwSubmissionError);
};

/**
 * Manage err during submission to throw error adapted to put react form in error.
 */
const throwSubmissionError = (err) => {
  throw new SubmissionError(err);
};

export {
  throwSubmissionError,
  isAdmin,
  formDispatch,
  loginState,
  application_name,
  emptyRole,
  users_per_page,
};
