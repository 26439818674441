import React from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { selectLoggedUser } from "../../slices/login.js";
import { isAdmin } from "../../utils/utils.js";

/**
 * Header NavBar.
 *
 * @returns Header Navigation tools bar.
 */
const NavBar = ({ t }) => {
  //-- get logged user
  const user = useSelector(selectLoggedUser);

  //-- no logged means no Navbar
  if (!user) return null;

  const isAdm = isAdmin(user, "accounts");

  return (
    <div className="nav-bar">
      <NavLink className="nav-bar-link" to="/my-account">
        {t("sections.account")}
      </NavLink>
      {isAdm && (
        <NavLink className="nav-bar-link" to="/users">
          {t("sections.users")}
        </NavLink>
      )}
    </div>
  );
};

NavBar.propTypes = {
  t: PropTypes.func.isRequired,
};

const TranslatedNavBar = withTranslation()(NavBar);

export default TranslatedNavBar;
