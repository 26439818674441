import React from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { emptyRole } from "../../../utils/utils";

/**
 * Return a HTML Select react component, adapted for given user and role.
 */
const SelectRole = ({ t, application, user, availableRoles, onSelect }) => {
  //-- 1 role by app
  const userRoleArray = user.roles.filter(
    (r) => r.application.name === application
  );
  const userRole = userRoleArray.length === 1 ? userRoleArray[0] : emptyRole;

  //-- all available roles for this application
  const roles = [emptyRole, ...availableRoles];

  return (
    <select value={userRole.name} onChange={onSelect}>
      {roles.map((role) => (
        <option key={role.name} value={role.name}>
          {t("userRole." + role.application.name + "." + role.name)}
        </option>
      ))}
    </select>
  );
};

/** Arguments checks */
SelectRole.propTypes = {
  /**
   * WithTranslation.
   */
  t: PropTypes.func.isRequired,
  /**
   * Application where to find related roles.
   */
  application: PropTypes.string.isRequired,
  /**
   * Current User.
   */
  user: PropTypes.object.isRequired,
  /**
   * Current available roles for given application.
   */
  availableRoles: PropTypes.array.isRequired,
  /**
   * Callback triggered when an option is selected.
   */
  onSelect: PropTypes.func.isRequired,
};

export default withTranslation()(SelectRole);
