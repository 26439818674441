import React from "react";
import { Field, reduxForm } from "redux-form";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import {
  sendEmailForgottenPassword,
  selectFPStatus,
} from "../../../slices/login";
import { required, isEmail } from "../../../utils/validators";
import TextField from "../../TextField/Textfield";
import { loginState } from "../../../utils/utils";
import { useSelector } from "react-redux";
import { formDispatch } from "../../../utils/utils";

const ForgottenPasswordForm = ({
  submitFailed,
  submitting,
  handleSubmit,
  t,
  invalid,
}) => {
  const fpStatus = useSelector(selectFPStatus);

  const getSubmitLabel = (status) => {
    switch (status) {
      case loginState.resolved:
        return t("forgetPassword.resubmit");
      default:
        return t("forgetPassword.submit");
    }
  };

  const getSubmitButtonClass = (status) => {
    switch (status) {
      case loginState.resolved:
        return "modal-button-secondary modal-button";
      default:
        return "modal-button-primary modal-button";
    }
  };

  return (
    <form
      onSubmit={handleSubmit((values, dispatch) =>
        formDispatch(dispatch, sendEmailForgottenPassword(values))
      )}
      className="forgotten-password-form"
      data-testid="forgotten-password-form"
    >
      <Field
        name="email"
        type="email"
        component={TextField}
        label={"user.email"}
        validate={[required, isEmail]}
      />
      {submitFailed && (
        <div className="form-error">{t("forgetPassword.error")}</div>
      )}

      <div className="connexion-buttons-container">
        <div className="form-item connexion-button">
          <button
            className={getSubmitButtonClass(fpStatus)}
            type="submit"
            disabled={submitting || invalid}
          >
            {getSubmitLabel(fpStatus)}
          </button>
        </div>
      </div>
    </form>
  );
};

ForgottenPasswordForm.propTypes = {
  submitFailed: PropTypes.bool.isRequired,
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func.isRequired,
  invalid: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
};

ForgottenPasswordForm.defaultProps = {
  submitting: false,
};

const TranslatedFPForm = withTranslation()(ForgottenPasswordForm);

const FPForm = reduxForm({
  form: "forgotten-form",
  enableReinitialize: true,
})(TranslatedFPForm);

export default FPForm;
