import React from "react";
import { Navigate, useSearchParams } from "react-router-dom";

/**
 * Wrap any page, and check if user is logged.
 * If logged return child else redirect to loggin page.
 *
 * @param {*} props children which is returned when user is alredy logged.
 * @returns child or redirection.
 */
const TokenPage = ({ children }) => {
  const [searchParams] = useSearchParams();
  let token = searchParams.get("token");
  return token ? children : <Navigate to="/login" />;
};

export default TokenPage;
