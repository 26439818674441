import React from "react";
import { withTranslation } from "react-i18next";
import RPForm from "./RPForm";
import { useSelector } from "react-redux";
import { selectRPStatus } from "../../../slices/login";
import { loginState } from "../../../utils/utils";
import TokenPage from "../../security/TokenPage";
import LoginLayout from "../Login/LoginLayout";

const RPPage = ({ t }) => {
  const rpStatus = useSelector(selectRPStatus);

  const getDescription = (status) => {
    switch (status) {
      case loginState.error:
        return t("resetPassword.error");
      case loginState.resolved:
        return t("resetPassword.success");
      default:
        return t("resetPassword.description");
    }
  };

  return (
    <TokenPage>
      <div className="login-page" data-testid="login-page">
        <LoginLayout
          title={t("resetPassword.title")}
          description={getDescription(rpStatus)}
        >
          <RPForm />
        </LoginLayout>
      </div>
    </TokenPage>
  );
};

export default withTranslation()(RPPage);
