import React from "react";
import VegaLogo from "../../images/logo-vega.svg";
import UserMenu from './UserMenu';
import NavBar from "./Navbar";

const vegaUrl = "http://www.vega-vocabulaire-egyptien-ancien.fr/";

const AppHeader = () => {

  return(
  <div className="app-header" data-testid="app-header">
    <div className="header-left">
      <div className="header-company">
        <a href={vegaUrl} target="_blank" rel="noreferrer">
          <img
            className="site-title-logo"
            title="VÉgA | Vocabulaire de l'Égyptien Ancien"
            alt="VÉgA | Vocabulaire de l'Égyptien&nbsp;Ancien"
            src={VegaLogo}
          />
          <div className="site-title-baseline">
            <span>Vocabulaire de l'Égyptien Ancien</span>
          </div>
        </a>
      </div>
    </div>

    <div className="header-nav">
      <NavBar />
    </div>
    <div className="header-user-menu">
      <UserMenu />
    </div>
  </div>
)};

export default AppHeader;
