import React from "react";
import { Field, reduxForm } from "redux-form";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import TextField from "../../TextField/Textfield";
import { required, isPassword, isEmail } from "../../../utils/validators";
import { connect } from "react-redux";
import VisiblePassword from "../../VisiblePassword/VisiblePassword";
import SelectRoleCreation from "./SelectRoleCreation";
import { application_name, emptyRole } from "../../../utils/utils";
import SwitchButton from "../../SwitchButton/SwitchButton";

const roleSelected = (role) => role && role !== emptyRole.name;
const oneRoleRequired = (value, allValues) =>
  !allValues ||
  (!roleSelected(allValues.vega))
    ? "required"
    : undefined;

const CreateAccountModal = ({
  t,
  handleSubmit,
  submitting,
  invalid,
  doSubmit,
  doCancel,
  vegaRoles,
}) => {
  return (
    <div className="modal-dialog">
      <div className="create-account-modal">
        <h1 id="create-account-modal-title" className="modal-title">
          {t("users.account-creation.title")}
        </h1>
        <form
          onSubmit={handleSubmit(doSubmit)}
          data-testid="create-account-form"
        >
          <div className="create-account-modal-content">
            <div className="two-colums">
              <Field
                name="firstName"
                type="text"
                component={TextField}
                label={"user.firstName"}
                validate={required}
                readOnly={false}
              />
              <Field
                name="lastName"
                type="text"
                component={TextField}
                label={"user.lastName"}
                validate={required}
                readOnly={false}
              />
            </div>
            <Field
              name="email"
              type="email"
              component={TextField}
              label={"user.email"}
              validate={[required, isEmail]}
              readOnly={false}
            />
            <VisiblePassword
              name="password"
              label={"user.password"}
              validate={[required, isPassword]}
            />
            <div className="two-colums">
              <div className="create-account-role">
                <h2>{t("users.roles.vega")}</h2>
                <SelectRoleCreation
                  application={application_name.vega}
                  availableRoles={vegaRoles}
                  validate={oneRoleRequired}
                />
              </div>
            </div>
            <div className="two-colums create-account-role isAdmin">
              <h2>{t("userRole.accounts.admin")}</h2>
              <Field
                name="isAdmin"
                component={(field) => (
                  <SwitchButton
                    id="Admin"
                    name={field.input.name}
                    className="isAdminSwitch"
                    value={field.input.value}
                    onChange={field.input.onChange}
                  />
                )}
                readOnly={false}
              />
            </div>
          </div>
          <div className="create-account-modal-buttons">
            <button
              className="modal-button-secondary modal-button"
              type="button"
              onClick={doCancel}
              data-testid="create-account-cancel"
              disabled={submitting}
            >
              {t("users.account-creation.cancel")}
            </button>
            <button
              className="modal-button-primary modal-button"
              type="submit"
              data-testid="create-account-confirm"
              disabled={invalid}
            >
              {t("users.account-creation.confirm")}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

CreateAccountModal.propTypes = {
  t: PropTypes.func.isRequired,
  doSubmit: PropTypes.func.isRequired,
  doCancel: PropTypes.func.isRequired,
  vegaRoles: PropTypes.array.isRequired,
};

const TranslatedCreateAccountModal = withTranslation()(CreateAccountModal);

const ReduxCreateAccountForm = reduxForm({
  form: "create-account-form",
  enableReinitialize: false,
})(TranslatedCreateAccountModal);

const ConnectedCreateAccountForm = connect((state) => ({
  initialValues: {}, // pull initial values
}))(ReduxCreateAccountForm);

export default ConnectedCreateAccountForm;
