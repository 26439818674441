import http from "../utils/http-common.js";
class LoginDataService {
  login({ email, password }) {
    return http.post("/auth/login", { email: email, password: password });
  }
  logout() {
    return http.get(`/auth/logout`);
  }
  user() {
    return http.get("/auth/user");
  }
  forgottenPassword({ email }) {
    return http.post("/auth/forgotten-password", { email: email });
  }
  reinitPassword({ password, passwordConfirmation, token }) {
    return http.post("/auth/reinit-password", {
      password,
      passwordConfirmation,
      token,
    });
  }
}
export default new LoginDataService();
