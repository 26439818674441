/* eslint-disable jsx-a11y/alt-text */
import React, { Suspense } from "react";
import { render } from "react-dom";
import { Provider } from "react-redux";
import reportWebVitals from "./utils/reportWebVitals";
import store from "./utils/configureStore";
import "./styles/style.scss";
import "./utils/i18n";
import VegaLogo from "./images/logo-vega.svg";
import Root from "./Root";

const VEgASuspense = () => {
  return (
    <>
      <div className="app-header suspens" data-testid="app-header">
        <div className="header-left">
          <div className="header-company">
            <img className="site-title-logo" src={VegaLogo} />
          </div>
        </div>
      </div>
      <center>
        <div className="rotateLoading" />
      </center>
    </>
  );
};

render(
  <Provider store={store}>
    <Suspense fallback={VEgASuspense()}>
      <Root />
    </Suspense>
  </Provider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
