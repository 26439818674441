import React from "react";
import { withTranslation } from "react-i18next";
import FPForm from "./FPForm";
import { useSelector } from "react-redux";
import { selectFPEmail, selectFPStatus } from "../../../slices/login";
import { loginState } from "../../../utils/utils";
import LoginLayout from "../Login/LoginLayout";

const FPPage = ({ t }) => {
  const fpStatus = useSelector(selectFPStatus);
  const fpEmail = useSelector(selectFPEmail);

  const getDescription = (status) => {
    switch (status) {
      case loginState.error:
        return t("forgetPassword.description");
      case loginState.resolved:
        return t("forgetPassword.success", { email: fpEmail });
      default:
        return t("forgetPassword.description");
    }
  };

  return (
    <div className="login-page" data-testid="login-page">
      <LoginLayout
        title={t("forgetPassword.title")}
        subTitle={t("login.sub-title")}
        description={getDescription(fpStatus)}
      >
        <FPForm />
      </LoginLayout>
    </div>
  );
};

export default withTranslation()(FPPage);
