import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";

const generateRoleInApp = (role, t) => {
  const roleString = t("userRole." + role.application.name + "." + role.name);
  const appString = t("apps." + role.application.name);
  return roleString + " " + appString;
};

const filteredApps = ["vega"];

const MyAccountApps = ({ user, t }) => {
  const vegaUrl = window.location.origin.replace('accounts', 'app');

  return (
    <div className="my-account-page-apps" data-testid="myaccount-apps">
      <div className="my-account-page-apps-title">
        {t("user.myApplications")}
      </div>
      <div className="my-account-page-apps-content">
        <div
          className="my-account-page-app my-account-page-app--vega"
          data-testid="myaccount-app-vega"
        >
          <div
            className="my-account-page-app-logo"
            data-testid="myaccount-app-vega-logo"
          />
          {user.roles
            .filter((element, pos, array) =>
              filteredApps.includes(element.application.name)
            )
            .map((element, pos) => (
              <p key={pos}>{generateRoleInApp(element, t)}</p>
            ))}
          <a className="my-account-page-app-button" href={vegaUrl}>
            <span>{t("user.accessVega")}</span>
          </a>
        </div>
      </div>
    </div>
  );
};

MyAccountApps.propTypes = {
  user: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

const TranslatedMyAccountApps = withTranslation()(MyAccountApps);

const InitializeMyAccountApps = connect((state) => ({
  user: state.login.loggedUser,
}))(TranslatedMyAccountApps);

export default InitializeMyAccountApps;
