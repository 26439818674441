import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import LoginPage from "./components/pages/Login/LoginPage";
import UsersPage from "./components/pages/Users/UsersPage";
import MyAccountPage from "./components/pages/MyAccount/MyAccountPage";
import "./styles/style.scss";
import "./utils/i18n";
import { loginState } from "./utils/utils.js";
import { fetchLoggedUser } from "./slices/login.js";
import AppHeader from "./components/AppHeader/AppHeader";
import FPPage from "./components/pages/ForgottenPassword/FPPage";
import RPPage from "./components/pages/ReinitPassword/RPPage";

/**
 * Application root element.
 *
 * @returns Header Navigation tools bar.
 */
const Root = () => {
  const dispatch = useDispatch();
  const [logState, setLogState] = useState(loginState.pending);

  /**
   * Fetch user from backend
   */
  useEffect(() => {
    dispatch(fetchLoggedUser())
      .then(setLogState(loginState.resolved))
      .catch((err) => setLogState(loginState.error));

    return () => {
      setLogState(loginState.pending); //-- unmount component reinitialise state
    };
  }, [dispatch]);

  return logState === loginState.pending ? (
    //-- waiting get user return.
    <div></div>
  ) : (
    //-- resolved means already logged so return app as usual,
    //-- in error means not already logged,
    //-- so return app as usual and client will log him onto login page.
    <Router>
      <AppHeader />
      <Routes>
        <Route exact path="/" element={<Navigate to="/login" />} />
        <Route exact path="/login" element={<LoginPage />} />
        <Route exact path="/forgotten-password" element={<FPPage />} />
        <Route exact path="/reinit-password" element={<RPPage />} />
        <Route exact path="/my-account" element={<MyAccountPage />} />
        <Route exact path="/users" element={<UsersPage />} />
        <Route render={() => <h1>404: page not found</h1>} />
      </Routes>
    </Router>
  );
};

export default Root;
