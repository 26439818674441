import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { withTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { fetchLogout, selectLoggedUser } from "../../slices/login";

const UserMenu = ({ t }) => {
  const dispatch = useDispatch();
  const user = useSelector(selectLoggedUser);

  //-- hook to force redirection into
  //-- javascript method
  const nav = useNavigate();

  if (!user) return null;

  /**
   * To reset logged user and be redirected to login.
   */
  const logout = () => {
    dispatch(fetchLogout()).then(() => nav("/login"));
  };

  return (
    <>
      <div>
        {user.firstName} {user.lastName}
      </div>
      <button
        type="button"
        className="link link--clear"
        onClick={logout}
        data-testid="userMenu-logout"
      >
        {t("logout.link")}
      </button>
    </>
  );
};

export default withTranslation()(UserMenu);
