import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import RoleDataService from "../services/RoleDataService";

const initialState = [];

//-- selectors
export const selectRoles = (state) => state.roles;

export const selectRolesVega = (state) => state.roles.map((r) => r.name === "");

export const createRole = createAsyncThunk(
  "roles/create",
  async ({ role_name, application_id }) => {
    const res = await RoleDataService.create({ role_name, application_id });
    return res.data;
  }
);
export const retrieveRoles = createAsyncThunk("roles/retrieve", async () => {
  const res = await RoleDataService.getAll();
  return res.data;
});
export const updateRole = createAsyncThunk(
  "roles/update",
  async ({ id, data }) => {
    const res = await RoleDataService.update(id, data);
    return res.data;
  }
);
export const deleteRole = createAsyncThunk("roles/delete", async ({ id }) => {
  await RoleDataService.delete(id);
  return { id };
});
export const deleteAllRoles = createAsyncThunk("roles/deleteAll", async () => {
  const res = await RoleDataService.deleteAll();
  return res.data;
});

const roleSlice = createSlice({
  name: "roles",
  initialState, //-- ici {app: name,
  //--                roles[]}
  extraReducers: {
    [createRole.fulfilled]: (state, action) => {
      state.push(action.payload);
    },
    [retrieveRoles.fulfilled]: (state, action) => {
      //-- ici group par app
      return [...action.payload];
    },
    [updateRole.fulfilled]: (state, action) => {
      const index = state.findIndex((role) => role.id === action.payload.id);
      state[index] = {
        ...state[index],
        ...action.payload,
      };
    },
    [deleteRole.fulfilled]: (state, action) => {
      let index = state.findIndex(({ id }) => id === action.payload.id);
      state.splice(index, 1);
    },
    [deleteAllRoles.fulfilled]: (state, action) => {
      return [];
    },
  },
});
const { reducer } = roleSlice;
export default reducer;
