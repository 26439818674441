import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import UserDataService from "../services/UserDataService";
import { users_per_page } from "../utils/utils";

const initialState = {
  list: [], //-- users list from backend
  currentPageId: 0,
  count: 0,
};

//------------------------------- Selectors ----------------------------------//
export const selectCount = (state) => state.users.count;
export const selectUsers = (state) => state.users.list;
export const selectCurrentPageId = (state) => state.users.currentPageId;

const selectUserById = (state, id) => state.users.list.find((u) => u.id === id);
const selectRolesByAppName = (state, applicationName) =>
  state.roles.filter((r) => r.application.name === applicationName);

const selectRoleId = (state, applicationName, roleName) =>
  selectRolesByAppName(state, applicationName).find((r) => r.name === roleName)
    .id;

//------------------------------- Operations ---------------------------------//
/**
 * Create a new user, building its role ids based on its affected role names
 */
export const createUserWithRoles = (user, roles) => {
  return (dispatch, getState) => {
    const roleIds = roles
      .filter((r) => r.role)
      .map((r) => selectRoleId(getState(), r.application, r.role));

    return dispatch(createUser({ role_ids: roleIds, user })).then(() =>
      dispatch(nextPage(selectCurrentPageId(getState())))
    );
  };
};

/**
 * Update internal user roles when affect new role.
 *
 * @param {event} event
 */
export const updateUserRoles = (applicationName, roleName, userId) => {
  return (dispatch, getState) => {
    const user = selectUserById(getState(), userId);
    let roleIds = user.roles
      .filter((r) => r.application.name !== applicationName)
      .map((r) => r.id);

    if (roleName !== "") {
      const newRoleId = selectRoleId(getState(), applicationName, roleName);
      roleIds = [newRoleId, ...roleIds];
    }

    const data = {
      role_ids: roleIds,
      user: user,
    };
    dispatch(updateUser({ id: user.id, data: data }));
  };
};

/**
 * Update internal user roles when affect new role.
 *
 * @param {event} event
 */
export const nextPage = (nextPageId) => {
  return (dispatch, getState) => {
    dispatch(setNextPage(nextPageId));
    dispatch(
      retrieveUsers({
        limit: users_per_page,
        offset: nextPageId * users_per_page,
      })
    );
  };
};

/**
 * Delete user and refresh page.
 *
 * @param {*} id
 * @returns
 */
export const deleteUserNRefresh = (id) => {
  return (dispatch, getState) => {
    return dispatch(deleteUser(id)).then(() =>
      dispatch(nextPage(selectCurrentPageId(getState())))
    );
  };
};

//-------------------------------- Actions -----------------------------------//
export const fetchCount = createAsyncThunk("users/count", async () => {
  const res = await UserDataService.count();
  return res.data;
});

export const createUser = createAsyncThunk(
  "users/create",
  async ({ role_ids, user }) => {
    const res = await UserDataService.create({ role_ids, user });
    return res.data;
  }
);

export const retrieveUsers = createAsyncThunk(
  "users/retrieve",
  async ({ limit = 8, offset = 0 } = {}) => {
    const res = await UserDataService.getAll(limit, offset);
    return res.data;
  }
);
export const updateUser = createAsyncThunk(
  "users/update",
  async ({ id, data }) => {
    const res = await UserDataService.update(id, data);
    return res.data;
  }
);
export const deleteUser = createAsyncThunk("users/delete", async ({ id }) => {
  await UserDataService.delete(id);
  return { id };
});
export const deleteAllUsers = createAsyncThunk("users/deleteAll", async () => {
  const res = await UserDataService.deleteAll();
  return res.data;
});
export const findUsersByEmail = createAsyncThunk(
  "users/findByEmail",
  async ({ email }) => {
    const res = await UserDataService.findByEmail(email);
    return res.data;
  }
);

//---------------------------------- Slice -----------------------------------//
const userSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    setNextPage: (state, action) => {
      state.currentPageId = action.payload;
    },
  },
  extraReducers: {
    [retrieveUsers.fulfilled]: (state, action) => {
      state.list = [...action.payload];
      // return [...action.payload];
      return state;
    },
    [updateUser.fulfilled]: (state, action) => {
      const index = state.list.findIndex(
        (user) => user.id === action.payload.id
      );
      state.list[index] = {
        ...state.list[index],
        ...action.payload,
      };
      return state;
    },
    [deleteUser.fulfilled]: (state, action) => {
      let index = state.list.findIndex(({ id }) => id === action.payload.id);
      state.list.splice(index, 1);
    },
    [deleteAllUsers.fulfilled]: (state, action) => {
      state.list = [];
      // return [];
      return state;
    },
    [findUsersByEmail.fulfilled]: (state, action) => {
      state.list = [...action.payload];
      // return [...action.payload];
      return state;
    },
    [fetchCount.fulfilled]: (state, action) => {
      state.count = action.payload;
      return state;
    },
  },
});

const { setNextPage } = userSlice.actions;

const { reducer } = userSlice;
export default reducer;
